import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Members from "../components/sections/members"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import NoFees from "../components/sections/no-fees"
import ProofOfWork from "../components/sections/proofofwork"
import SupportDay from "../components/sections/supportday"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <Header />
    <Members />
    <Features />
    <ProofOfWork />
    
    
    
    <SupportDay state={{ username: "Dailyness & Bitcoin" }} />
    <GetStarted />
    <NoFees />
    <Footer />
  </Layout>
)

export default IndexPage
