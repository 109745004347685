import React from "react"
import styled from "styled-components"
import appstore from "../../images/appstore.png"
import { Container, Section } from "../global"
import google from "../../images/google.png"


const GetStarted = () => (
  <StyledSection>
    <GetStartedContainer>
      <GetStartedTitle>Your Journey Continues</GetStartedTitle>
      <div style={{ flexDirection: "row" }}>
      <a href="https://apps.apple.com/us/app/day-the-dailyness-app/id1555873146 ">
        <img
          src={appstore}
          style={{ paddingRight: "10px", float: "left", paddingTop: "10px", width: "140px" }}
          alt="AppStore Logo"
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.perfectdayreactnative">
        <img
          src={google}
          style={{ paddingTop: "10px", width: "140px" , height: "49px" }}
          alt="PlayStore logo"
        />
      </a>
      </div>
    </GetStartedContainer>
  </StyledSection>
)

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`
