import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Container } from "../global"
import appstore from "../../images/appstore.png"
import google from "../../images/google.png"
import AnimatedNumber from "react-animated-number"
import firebase from "gatsby-plugin-firebase"

const Header = () => {
  const [amountRaised, setAmountRaised] = useState(0)

  useEffect(() => {
    firebase
      .firestore()
      .collection("cheers")
      .get()
      .then((querySnapshot) => {
        setAmountRaised(querySnapshot.docs.length * 5) //each cheerz is worth $5
      })
      .catch((error) => {})
  }, [])

  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "green-skew" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <Container style={{ paddingLeft: "36px", paddingRight: "36px" }}>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Dailyness & Bitcoin for All</Subtitle>
            <h1>Practice Daily. Earn Bitcoin.</h1>
            <h1>Always Free.</h1>
            <h2>
              The first dailyness app that pays you Bitcoin.
            </h2>
            <h1>Get started now!</h1>
            <br />
            <a href="https://apps.apple.com/us/app/day-the-dailyness-app/id1555873146 ">
              <img
                src={appstore}
                style={{ paddingRight: "10px", width: "48%" }}
                alt="AppStore Logo"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.perfectdayreactnative">
              <img
                src={google}
                style={{ paddingLeft: "10px", width: "48%" }}
                alt="PlayStore logo"
              />
            </a>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding: 100px 0 80px 0;
  }
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: #db9840;
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: left;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    text-align: center;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0;
    font-size: 32px;
    padding-top:2px;
    line-height: 34px;
    color: ${(props) => props.theme.color.primary};

    @media (max-width: ${(props) => props.theme.screen.lg}) {
      padding-top: 0px;
      font-size: 28px;
    }
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 24px;
    ${(props) => props.theme.font_size.regular}
    @media (max-width: ${(props) => props.theme.screen.sm}) {
      font-size: 1.0625rem;
      text-align: middle;
    }
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const StyledImage = styled(Img)`
  width: 400px;
  margin-left: 40px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 220px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 200px;
    display: flex;
    margin-bottom: 0px;
    flex-direction: column-reverse;
    margin-left: 0px;
  }
`
