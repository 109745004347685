import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>The Day System</Subtitle>
      <SectionTitle>Practice Daily. Earn Bitcoin.</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>
            <img
              style={{
                maxHeight: "30px",
                width: "auto",
                position: "relative",
                top: "5px",
                marginRight: "3px",
              }}
              alt="Feature"
              src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fintention.png?alt=media&token=6446f53a-ffe0-4e4b-a83d-1f59c185ce5e"
            />
            Feel Great
          </FeatureTitle>
          <FeatureText>
            Take the first step and publicly declare your intentions. Practice these every day.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            <img
              style={{
                maxHeight: "30px",
                width: "auto",
                position: "relative",
                top: "5px",
                marginRight: "3px",
              }}
              alt="Feature"
              src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fcount.png?alt=media&token=e55eddd6-2633-42b4-b157-8cb152171f4f"
            />
            Gain Confidence
          </FeatureTitle>
          <FeatureText>
            Your Day Count grows every day you satisfy your intentions. Build this up!
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            <img
              style={{
                maxHeight: "30px",
                width: "auto",
                position: "relative",
                top: "5px",
                marginRight: "3px",
              }}
              alt="Feature"
              src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fgrow.png?alt=media&token=8a9cbdb6-263b-4481-b605-224a7efeeb3c"
            />
            Earn Bitcoin
          </FeatureTitle>
          <FeatureText>Practice every day and collect points to earn the most Bitcoin possible.</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            <img
              style={{
                maxHeight: "30px",
                width: "auto",
                position: "relative",
                top: "5px",
                marginRight: "3px",
              }}
              alt="Feature"
              src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fcommunity.png?alt=media&token=8acbf6be-963b-4d61-b281-00b503cbe245"
            />
            Give Back
          </FeatureTitle>
          <FeatureText>Support the community with a monthly contribution. Sponsor the best in others. </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
      <br />
      <FeaturesGrid>
        <FeatureItem>
          <div className="testimonial-card">
            <div className="text">
              Day helps me stick to my fitness routine to look and feel great!
              It's the app that just works for me.
              <i className="fas fa-quote-right quote"></i>
            </div>
            <br />
            <br />
            <div className="footer">
              <div className="image"></div>
              <h3 className="person">Marie</h3>
            </div>
          </div>
        </FeatureItem>
        <FeatureItem>
          <div className="testimonial-card">
            <div className="text">
              Dailyness has transformed my life. Day helps me keep my streak
              alive so I know I'm always taking a step forward.{" "}
              <i className="fas fa-quote-right quote"></i>
            </div>
            <br />
            <br />
            <div className="footer">
              <div className="image2"></div>
              <h3 className="person">TheSchnaz</h3>
            </div>
          </div>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 4px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
