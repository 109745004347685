import React, { useState, useEffect } from "react"
import styled from "styled-components"
import appstore from "../../images/appstore.png"
import { Container, Section } from "../global"
import google from "../../images/google.png"
import AnimatedNumber from "react-animated-number"
import firebase from "gatsby-plugin-firebase"
import intention from "../../images/btc-meditation.png"
import together from "../../images/together1.png"
import { Link } from "gatsby"

const Members = () => {
  const [activeMembers, setActiveMembers] = useState([])
  const [activeMembersCount, setActiveMembersCount] = useState("*")
  let today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1
  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  today = `${mm}/${dd}/${yyyy}`

  useEffect(() => {
    firebase
      .firestore()
      .collection("days")
      .where("createdAt", "==", today)
      .get()
      .then((querySnapshot) => {
        let localActiveMembers = []

        querySnapshot.forEach((doc) => {
          localActiveMembers.push(doc.data())
        })

        setActiveMembers(localActiveMembers)
        setActiveMembersCount(localActiveMembers.length)
        if (localActiveMembers.length === 0) {
          // If no active members today, fake it
        }
      })
      .catch((error) => {})
  }, [])

  return (
    <Section id="members">
      <StyledContainer>
        <Subtitle>Practice Together</Subtitle>
        <SectionTitle>{activeMembersCount} Active Members Today</SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <FeatureTitle>
              <img
                style={{
                  maxHeight: "30px",
                  width: "auto",
                  position: "relative",
                  top: "5px",
                  marginRight: "3px",
                }}
                alt="Proof of Work"
                src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fcommunity.png?alt=media&token=8acbf6be-963b-4d61-b281-00b503cbe245"
              />
              The Day Community
            </FeatureTitle>

            <FeatureText>
              Over {activeMembersCount} people completed their daily practice
              today. Join our active and supportive community, practice with us!
            </FeatureText>
            <FeatureTitle>
              {activeMembers.map((l, i) => (
                <Link to={`/members/${l.displayUsername}`}>
                  <img
                    style={{
                      maxHeight: "30px",
                      width: "auto",
                      position: "relative",
                      top: "5px",
                      marginRight: "3px",
                    }}
                    alt="Member"
                    src={l.avatarURL}
                  />
                </Link>
              ))}
            </FeatureTitle>
          </FeatureItem>
          <FeatureItem>
            <img
              style={{
                maxHeight: "300px",
                width: "auto",
              }}
              alt="Feature"
              src={together}
            />
          </FeatureItem>
        </FeaturesGrid>
      </StyledContainer>
    </Section>
  )
}

export default Members

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 4px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`